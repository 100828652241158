import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import Layout from "../../components/Layout";
import { connect } from 'react-redux'
import { getTerms } from '../../reducers/Terms/actions'
import SideNav from "../../components/SideNav/SideNav";
import "./styles.scss";

const TermsAndConditions = ({ terms, getTerms }) => {
  let url = "";
  let id = null;
  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
    id = url.searchParams.get("terms-and-conditions");
  }

  useEffect(() => {
    getTerms({ type: 'common' })
  }, [getTerms])

  const returnSeoDescription = (id) => {
    switch(id) {
      case '0':
        return 'Правила за ползване на уебсайта'
      case '1':
        return 'Общи условия за партньори на Building Companion'
      case '2':
        return 'Общи условия за допълнителни услуги, предоставяни от Building Companion'
      default: return ''
    }
  }

  return (
    <Layout description={returnSeoDescription(id)}>
      <div className="terms-wrapper">
        <div className="terms-title-wrapper mt-5">
          <div className="discount-terms-page-title">
            <h1 className="font-weight-bold">Условия за ползване</h1>
          </div>
        </div>
        <div className="terms-container">
          <Row className="mt-5">
            <Col className='mb-5 sidenav-menu-col' xs={12} md={4} lg={4}>
              {
                terms && terms[id].title
                  ? <SideNav
                    terms={terms}
                    termTitle={terms[id].title}
                    privacyPolicy
                    termsAndConditions
                  />
                  : null
              }
            </Col>
            <Col className="terms-of-use-col" lg={8} md={8}>
              <div className="mb-5 discount-terms-title w-75">
                <h2
                  className="mt-2"
                  id="first-section">
                  {terms && terms[id].title}
                </h2>
              </div>
              <div className="discount-terms mt-2">
                {terms && terms[id] &&
                  terms[id].sections.map(term => (
                    <div
                      name={`${term.id}`}
                      key={term.id}
                      className="mb-5"
                    >
                      <h3
                        className="h4"
                        id={term.id}
                      >
                        {term.title}
                      </h3>
                      <p
                        className='mt-3'
                        dangerouslySetInnerHTML={term && { __html: term.description }}
                      />
                    </div>
                  ))
                }
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    terms: state.terms.common
  };
};

const mapDispatchToProps = {
  getTerms
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
